.custom-tooltip{
	display: inline-block;
	position: relative;
	
	.tooltip-content{
		position: absolute;
		width: 230px;	
		background-color: #fff;
		border-radius: var(--border-radius-base);
		border: 1px solid var(--border-color);
		padding: 15px;
		font-size: 12px;
		margin-bottom: 0;
		right: 0;
		z-index: 2;
		box-shadow: 0px 7px 15px rgba(61, 70, 83, 0.1);
		opacity: 0;
		visibility: hidden;
		@include transitionFast;
		
		p{
			margin-bottom: 0;
		}
	}
	&:hover{
		.tooltip-content{
			opacity: 1;
			visibility: visible;
		}
	}
}