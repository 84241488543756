// dz-bnr-inr
.dz-bnr-inr{
    height: 450px;
    background-size: cover;
	background-position: center;
	position: relative;
	z-index:2;
	width: 100%;
	background-image: url(../images/banner/bnr.png);
	background-color: $dark-secondry;
	position: relative;
	
	@include respond('tab-port'){
		height: 350px;
	}
	@include respond('phone-land'){
		height: 280px;
	}
	
	.container{
		display: table;
		height: 100%;
		
	}
	.dz-bnr-inr-entry{
	    height: 450px;
		vertical-align: middle;
		display: table-cell;

		@include respond('tab-port'){
			height: 350px;
		}
		@include respond('phone-land'){
			height: 280px;
		}
	}
	
	h1 {
		font-size: 70px;
		margin-bottom: 1rem;
		line-height: 1.2;
		color:#fff;
		font-family: $font-family-base;
		text-transform: capitalize;
		text-shadow: 0px 30px 70px #002622;
		
		@include respond('tab-land'){
			font-size: 48px;
		}
		@include respond('tab-port'){
			font-size: 36px;
			margin-bottom: 5px;
		}
		@include respond('phone-land'){
			font-size: 32px;
		}
	}
	.text{
		font-size: 22px;
		font-weight: 300;
		line-height: 1.7;
		color: $primary;

		@include respond('tab-land'){
			font-size: 18px;
		}
		@include respond('tab-port') {
			font-size: 16px;
		}
		@include respond('phone-land') {
			font-size: 14px;
		}
	}
}
.breadcrumb-row{
	ul {
		background:var(--card-bg);
		padding: 0;
		display:inline-block;
		padding: 10px 30px;
		border-radius:var(--border-radius-base);
		
		@include respond('phone'){
			padding: 10px 18px;
		}
		
		li {
			padding: 0;
			margin-right: 3px;
			display: inline-block;
			font-size: 15px;
			font-weight: 500;
			text-transform: capitalize;
			color: var(--title);
			
			&.active{
				color: var(--title) !important;
				a{
					color: var(--title);			
				}
			}
			a{
				color: var(--primary);	
			}
			i{
				color: var(--primary);
				margin-right: 10px;
			}
			@include respond('phone'){
				font-size: 14px;
			}
		}
		.breadcrumb-item + .breadcrumb-item{
			padding-left: 12px;
			
			&::before{
				content: "\f101";
				font-family: 'Font Awesome 5 Free';
				font-weight: 900;
				color: var(--primary);
				padding-right: 12px;	
			}
			@include respond('phone'){
				padding-left: 8px;
				&::before{
					font-size: 12px;
					padding-right: 8px;
					margin-top: 2px;
				}
			}
		}
	}
}