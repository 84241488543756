// range-slider
.range-slider{
	&.style-1{
		padding: 10px 15px 40px;
		.noUi-horizontal{
			height: 8px;
			.noUi-handle {
				width: 24px;
				height: 24px;
				border-radius: 50%;
				border: 0;
				background-color: var(--secondary);
				box-shadow: none;
				top: -9px;
				&:before,
				&:after{
					content: none;
				}
				
				.noUi-tooltip {
					border: 0px;
					background: var(--primary);
					color: rgb(255, 255, 255);
					border-radius: 4px;
					font-size: 13px;
					bottom: -35px;
					font-weight: 600;
					padding: 2px 5px;
					&:after{
						content: "";
						position: absolute;
						background-color: inherit;
						width: 10px;
						height: 10px;
						top: 4px;
						margin-left: -4px;
						left: 50%;
						transform: rotate(45deg) translate(-50%, -50%);
					}
				}
			}
		}
		.noUi-target {
			background: #F0EEFF;
			border-radius: 4px;
			border: 0;
			box-shadow: none;
			
			.noUi-connect {
				background: var(--secondary);
			}
		}
	}
}
.custom-nouislider{
	.noUi-marker-horizontal.noUi-marker {
		display: none;
	}
	.noUi-handle.noUi-handle-lower{
		&:after,
		&:before{
			display: none;
		}
	}
	.noUi-target{
		border-radius: 4px;
		border: 0;
		box-shadow: none;
	}
	.noUi-connect{
		background: var(--primary);
		border-radius: 4px;
	}
	.noUi-pips-horizontal{
		font-size: 11px;
		font-weight: 500;
		color: $body-color;
		padding: 0;
		height: 30px;
		left: 2px;
		width: calc(100% - 10px);
	}
	.noUi-connects{
		border-radius: 4px;
		background-color: #dde4ee;
	}
	.noUi-horizontal{
		height: 4px;
		
		.noUi-handle{
			width: 16px;
			height: 16px;
			border-radius: 50%;
			border: 3px solid var(--primary);
			right: -7px;
		}
	}
	.noUi-value{
		&:after{
			content: 'x';
			position: absolute;
			font-size: 13px;
			color: var(--body-color);
			top: 50%;
			right: -7px;
			transform: translateY(-50%);
		}
	}	
}