.privacy-policy {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  margin-top: 100px;
}

h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

h2 {
  font-size: 20px;
  margin-top: 30px;
  margin-bottom: 15px;
}

p {
  margin-bottom: 15px;
}

ul {
  margin-bottom: 15px;
  padding-left: 20px;
}

li {
  margin-bottom: 5px;
}
